.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: #61dafb;
}

.App-main {
  height: calc(100vh - 50px);
  overflow: auto;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.cfstable {
  border-spacing: 0px;
  text-align: center;
}
.cfstable thead.cfsrow {
  background: lightgray;
  font-weight: 700;
}

.cfstable p {
  margin: 0px;
}

.cfstable .cfsrow:first-child .cfscell {
  border-top: 2px solid gray;
}
.cfstable .cfsrow:last-child .cfscell {
  border-bottom: 2px solid gray;
}

.cfstable .cfsrow .cfscell:first-child {
  border-left: 2px solid gray;
}

.cfstable .cfsrow .cfscell:last-child {
  border-right: 2px solid gray;
}

.cfsrow .cfscell:nth-child(2) {
  text-align: left;
  max-width: 360px;
  min-width: 360px;
  word-wrap: break-word;
}

.cfscell {
  padding: 5px;
  border: 1px solid gray;
}

h1 + .MuiBox-root {
  border-top: 0px !important;
}
/* 
.MuiRadio-root {
  padding: 0px !important;
} */

.cfscell-basic {
  padding: 5px;
  border: 1px solid gray;
}

.cfsrow .cfscell-basic:nth-child(2) {
  text-align: center;
  word-wrap: break-word;
}

.auto-width-cell {
  width: auto;
  white-space: nowrap;
}

.website-uri {
 word-wrap: anywhere;
 max-width: 360px;
 min-width: 360px;
}

* {
  font-family: 'Roboto', sans-serif !important;
}